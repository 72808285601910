module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"elements":["h2"],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/netlify-cms/index.js","enableIdentityWidget":true,"publicPath":"admin","htmlTitle":"Content Manager","includeRobots":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"1233","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"breakpoints":["45em","64em","80em"],"space":[0,4,8,16,32,64,128,256,512],"fonts":{"body":"\"Inter\", system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif","heading":"inherit"},"fontSizes":[12,14,16,20,24,32,48,64,96],"fontWeights":{"body":400,"heading":600,"bold":700},"lineHeights":{"body":1.5,"heading":1.125},"colors":{"white":"#ffffff","black":"#000000","gray":"#e5e5f2","lightgray":"#edeff5","lightergray":"#f9f9fb","purple":"#6E71D8","purplegray":"#525376","lightpurplegray":"#585979","lighterpurplegray":"#a8a9bb","orange":"#ff8b49","blue":"#4992ff","lightblue":"#eff5ff"},"layout":{"container":{"mx":"auto","flexGrow":1,"maxWidth":"1440px","width":"100%","paddingLeft":["1.5rem","2rem","3rem","7.5rem"],"paddingRight":["1.5rem","2rem","3rem","7.5rem"]}},"styles":{"root":{"fontFamily":"body","lineHeight":"body","fontWeight":"body"},"h1":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":5},"h2":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":4},"h3":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":3},"h4":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":2},"h5":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":1},"h6":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":0},"p":{"color":"text","fontFamily":"body","fontWeight":"body","lineHeight":"body"},"img":{"maxWidth":"100%"},"srOnly":{"border":"0 !important","clip":"rect(1px, 1px, 1px, 1px) !important","clipPath":"inset(50%) !important","height":"1px !important","margin":"-1px !important","overflow":"hidden !important","padding":"0 !important","position":"absolute !important","width":"1px !important","whiteSpace":"nowrap !important"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e0ebf7b6bd5aa681b1f29cfda3973985"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
